var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.items.length ? "tableFixHead" : "" }, [
    _c(
      "table",
      [
        _c(
          "thead",
          _vm._l(_vm.fields, function (field, index) {
            return _c("th", { key: "tr-" + index, class: field.thClass }, [
              _vm._v("\n        " + _vm._s(field.label) + "\n      "),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm._l(_vm.items, function (item, index) {
          return _c("tbody", { key: "tr-" + index }, [
            !_vm.isLast(item, index)
              ? _c(
                  "tr",
                  {
                    class: {
                      [_vm.getSemColor(item.id)]: true,
                    },
                  },
                  _vm._l(_vm.fields, function (field, index) {
                    return _c(
                      "td",
                      {
                        key: "td-" + index,
                        class: {
                          [field.tdClass]: field.tdClass,
                          [item.type.toLowerCase()]: field.key === "check1",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              field.type !== "checkbox"
                                ? _vm.drawValue(item, field, index)
                                : null
                            ) +
                            "\n          "
                        ),
                        field.key === "note_counter"
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: _vm.toTooltipTask(
                                      item.tasks.filter(
                                        (task) => task.task_type.value === 2
                                      ),
                                      "Note"
                                    ),
                                    expression:
                                      "\n              toTooltipTask(\n                item.tasks.filter((task) => task.task_type.value === 2),\n                'Note'\n              )\n            ",
                                  },
                                ],
                                staticClass: "info-box",
                                attrs: {
                                  role: item.tasks.filter(
                                    (task) => task.task_type.value === 2
                                  ).length
                                    ? "button"
                                    : null,
                                },
                                on: {
                                  click: function ($event) {
                                    item.tasks.filter(
                                      (task) => task.task_type.value === 2
                                    ).length
                                      ? _vm.openNoteDetailModal(
                                          item.tasks.filter(
                                            (task) => task.task_type.value === 2
                                          )
                                        )
                                      : null
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      item.tasks.filter(
                                        (task) => task.task_type.value === 2
                                      ).length
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        field.type === "checkbox" && item.enabled
                          ? _c("base-checkbox", {
                              staticClass: "check",
                              attrs: { name: "check", disabled: !item.enabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onCheck($event, index, item)
                                },
                              },
                              model: {
                                value: _vm.inputVal[item.id].check,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputVal[item.id], "check", $$v)
                                },
                                expression: "inputVal[item.id].check",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isLast(item, index)
              ? _c(
                  "tr",
                  {
                    class: {
                      [_vm.getSemColor(item.id)]: true,
                    },
                  },
                  _vm._l(_vm.fields, function (field, index) {
                    return _c(
                      "td",
                      {
                        key: "td-" + index,
                        class: {
                          [field.tdClass]: field.tdClass,
                          [item.type.toLowerCase()]: field.key === "check1",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              field.type !== "checkbox"
                                ? _vm.drawValue(item, field, index)
                                : null
                            ) +
                            "\n          "
                        ),
                        field.key === "note_counter"
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: _vm.toTooltipTask(
                                      item.tasks.filter(
                                        (task) => task.task_type.value === 2
                                      ),
                                      "Note"
                                    ),
                                    expression:
                                      "\n              toTooltipTask(\n                item.tasks.filter((task) => task.task_type.value === 2),\n                'Note'\n              )\n            ",
                                  },
                                ],
                                staticClass: "info-box",
                                attrs: {
                                  role: item.tasks.filter(
                                    (task) => task.task_type.value === 2
                                  ).length
                                    ? "button"
                                    : null,
                                },
                                on: {
                                  click: function ($event) {
                                    item.tasks.filter(
                                      (task) => task.task_type.value === 2
                                    ).length
                                      ? _vm.openNoteDetailModal(
                                          item.tasks.filter(
                                            (task) => task.task_type.value === 2
                                          )
                                        )
                                      : null
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      item.tasks.filter(
                                        (task) => task.task_type.value === 2
                                      ).length
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        field.type === "checkbox" && item.enabled
                          ? _c("base-checkbox", {
                              staticClass: "check",
                              attrs: { name: "check", disabled: !item.enabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onCheck($event, index, item)
                                },
                              },
                              model: {
                                value: _vm.inputVal[item.id].check,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputVal[item.id], "check", $$v)
                                },
                                expression: "inputVal[item.id].check",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isLast(item, index)
              ? _c("tr", [
                  _c("td", { attrs: { colspan: "3" } }),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-left subtotal" }, [
                    _vm._v("SUBTOTALE"),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "xxxsw subtotal" }),
                  _vm._v(" "),
                  _c("td", { staticClass: "sw text-right subtotal" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.toLocaleCurrency(
                            _vm.subtotals[item.registry_id].gross
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "sw text-right subtotal" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.toLocaleCurrency(
                            _vm.subtotals[item.registry_id].money
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "xxxsw subtotal" }),
                ])
              : _vm._e(),
          ])
        }),
        _vm._v(" "),
        _vm.items.length
          ? _c("tbody", [
              _c("tr", [
                _c("td", { attrs: { colspan: "3" } }),
                _vm._v(" "),
                _c("td", { staticClass: "text-left" }, [_vm._v("TOTALE")]),
                _vm._v(" "),
                _c("td", { staticClass: "xxxsw" }),
                _vm._v(" "),
                _c("td", { staticClass: "sw text-right" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.toLocaleCurrency(_vm.totalgross())) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "sw text-right" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.toLocaleCurrency(_vm.totalmoney())) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "xxxsw" }),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.items.length
          ? _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "center !important",
                      height: "40px",
                    },
                    attrs: { colspan: _vm.fields.length },
                  },
                  [
                    _vm._v(
                      "\n          Non ci sono informazioni da mostrare\n        "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
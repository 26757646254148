var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "", id: "collapse-1" } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch()
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c(
                        "b-row",
                        [
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button-toolbar",
                                  [
                                    _c(
                                      "b-button-group",
                                      [
                                        _c("base-input", {
                                          attrs: {
                                            name: "Anagrafica",
                                            label: "Anagrafica",
                                            readonly: true,
                                          },
                                          model: {
                                            value:
                                              _vm.form.input_label_registry,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "input_label_registry",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.input_label_registry",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Ricerca Veloce",
                                            },
                                            on: {
                                              click:
                                                _vm.openQuickSearchRegistry,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "search" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.form.input_label_registry
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mt-4 btn-quick ml-1",
                                                attrs: {
                                                  size: "sm",
                                                  text: "Button",
                                                  variant: "lisaweb",
                                                  title: "Elimina Anagrafica",
                                                },
                                                on: {
                                                  click: _vm.resetRegistryId,
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("quick-search-registry-modal", {
                            ref: "quickSearchRegistry",
                            on: { input: _vm.handleRegistryModalInput },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _vm.registry_data
                              ? _c("span", {
                                  staticClass: "info",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.toInfoData(
                                        _vm.registry_data,
                                        "registry"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.showGroups
                            ? _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      name: "groups-select",
                                      label: "Gruppi",
                                      options: _vm.opt_groups,
                                    },
                                    on: { select: _vm.onSelectRegistryGroup },
                                    model: {
                                      value: _vm.filter.byRegistryGroup.id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byRegistryGroup,
                                          "id",
                                          $$v
                                        )
                                      },
                                      expression: "filter.byRegistryGroup.id",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showRelations
                            ? _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      name: "relations-multiselect",
                                      label: "Relazioni",
                                      options: _vm.opt_relations,
                                      multiple: true,
                                    },
                                    on: {
                                      select: _vm.onSelectRegistryRegistry,
                                    },
                                    model: {
                                      value: _vm.byRegistryRegistry.id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.byRegistryRegistry,
                                          "id",
                                          $$v
                                        )
                                      },
                                      expression: "byRegistryRegistry.id",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c("base-month-picker", {
                              attrs: {
                                id: "to",
                                vid: "periodo_a",
                                name: "periodo_a",
                                label:
                                  "Escludi quietanzamenti con scadenza a partire dal",
                                clearable: true,
                              },
                              model: {
                                value: _vm.filter.byCalendar.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "to", $$v)
                                },
                                expression: "filter.byCalendar.to",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                  disabled: !_vm.filter.byRegistry.id,
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter()
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [
            _c(
              "div",
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.isLoading && _vm.items.length
                        ? "Elaborazione in corso, attendere..."
                        : ""
                    ),
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-overlay",
                  {
                    attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "overlay",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center mt-5" },
                                [
                                  _c("base-icon", {
                                    attrs: {
                                      name: "loading",
                                      width: "35",
                                      height: "35",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("p", { attrs: { id: "cancel-label" } }, [
                                    _vm._v("Operazione in corso..."),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3374301958
                    ),
                  },
                  [
                    !_vm.isLoading
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: Object.keys(_vm.model).length,
                                        expression: "Object.keys(model).length",
                                      },
                                    ],
                                    staticClass: "mb-2",
                                    attrs: {
                                      type: "button",
                                      pressed: _vm.myToggle,
                                      variant: "light",
                                      size: "sm",
                                      disabled: _vm.isToggling,
                                    },
                                    on: {
                                      "update:pressed": function ($event) {
                                        _vm.myToggle = $event
                                      },
                                      click: _vm.onToggleSelectAll,
                                    },
                                  },
                                  [
                                    _c("b-spinner", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isToggling,
                                          expression: "isToggling",
                                        },
                                      ],
                                      attrs: { small: "", type: "grow" },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.myToggle
                                            ? "Deseleziona"
                                            : "Seleziona"
                                        ) +
                                        " tutti"
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("sesana", {
                              attrs: {
                                fields: _vm.fields,
                                items: _vm.items,
                                semColor: _vm.toSemColor,
                                elaColor: _vm.toElaColor,
                              },
                              on: {
                                open_note: _vm.openNoteModal,
                                check: _vm.onSelectRow,
                                submit: _vm.onSubmit,
                              },
                              model: {
                                value: _vm.model,
                                callback: function ($$v) {
                                  _vm.model = $$v
                                },
                                expression: "model",
                              },
                            }),
                            _vm._v(" "),
                            _c("b-row", { staticClass: "table-extra-footer" }, [
                              _c("p", { staticClass: "title" }, [
                                _vm._v("Da Incassare:"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "sem-green" }),
                              _vm._v(" "),
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Recuperabile Totalmente"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "sem-yellow" }),
                              _vm._v(" "),
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Recuperabile Parzialmente"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "sem-red" }),
                              _vm._v(" "),
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Non Recuperabile"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("b-row", { staticClass: "table-extra-footer" }, [
                              _c("p", { staticClass: "title" }, [
                                _vm._v("Selezione:"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "check-green" }),
                              _vm._v(" "),
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Acconto"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "check-red" }),
                              _vm._v(" "),
                              _c("p", { staticClass: "label" }, [
                                _vm._v("Sospeso"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-extra-footer" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-2",
                                    attrs: {
                                      type: "button",
                                      variant: "lisaweb",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submit()
                                      },
                                    },
                                  },
                                  [_vm._v("Recupera")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("note-detail-modal", {
                              attrs: { items: _vm.notes },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }